import { useFormikContext, getIn } from 'formik';
import React, { useCallback, useEffect, useRef } from 'react';
import { Input, InputNumber } from 'antd';
const FormikInput = inputProps => {
    const { values, setFieldValue, handleBlur } = useFormikContext();
    const value = getIn(values, inputProps.name);
    const inputRef = useRef();
    const inputNumberRef = useRef();
    const isNumberType = inputProps.type === 'number';
    useEffect(() => {
        var _a;
        if (value !== undefined && value !== null) {
            if (isNumberType) {
                if (inputNumberRef.current && !inputNumberRef.current.value) {
                    inputNumberRef.current.value = value;
                }
            }
            else {
                (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.setValue(value);
            }
        }
    }, [value]);
    const persistedOnChange = useCallback(e => {
        var _a, _b;
        const valueToSet = isNumberType ? e : e.target.value;
        setFieldValue(inputProps.name, valueToSet);
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.setValue(valueToSet);
        (_b = inputProps.onChange) === null || _b === void 0 ? void 0 : _b.call(inputProps, e);
    }, [isNumberType, inputProps.name, setFieldValue, inputProps.onChange]);
    if (isNumberType) {
        return (React.createElement(InputNumber, Object.assign({ ref: inputNumberRef, defaultValue: value, onBlur: handleBlur, onWheel: e => e.target.blur() }, inputProps, { onChange: persistedOnChange })));
    }
    if (inputProps.type === 'password') {
        return (React.createElement(Input.Password, Object.assign({ ref: inputRef, defaultValue: value, onBlur: handleBlur }, inputProps, { onChange: persistedOnChange })));
    }
    return React.createElement(Input, Object.assign({ ref: inputRef, defaultValue: value, onBlur: handleBlur }, inputProps, { onChange: persistedOnChange }));
};
export default FormikInput;
